import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Bio from '../components/Bio';
import SEO from '../components/SEO';
import { Container, Link } from '../components/styled-components';

const ArticleWrapper = styled.article`
  width: 100%;
  margin-bottom: 30px;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);

  @media (min-width: 960px) {
    padding: 50px;
    margin-bottom: 50px;
  }
`;

const ArticleTitle = styled.h2`
  margin-bottom: 8px;
  color: #13022c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 900;
`;

const ArticleDate = styled.small`
  display: block;
  margin-bottom: 8px;
  color: #757575;
  font-size: 0.875rem;
`;

const ArticleBody = styled.section`
  font-family: Merriweather;
  font-weight: 300;
  line-height: 1.75rem;
  text-align: justify;
`;

function Article({ node }) {
  const title = node.frontmatter.title || node.fields.slug;
  const { date, path } = node.frontmatter;
  const description = node.frontmatter.description || node.excerpt;

  return (
    <ArticleWrapper>
      <ArticleTitle>
        <Link to={path}>{title}</Link>
      </ArticleTitle>

      <ArticleDate>{date}</ArticleDate>

      <ArticleBody dangerouslySetInnerHTML={{ __html: description }} />
    </ArticleWrapper>
  );
}

export default function Blog({ data }) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <SEO title="Blog" />

      <Container>
        <Bio />

        {posts.map(({ node }) => (
          <Article node={node} key={node.fields.slug} />
        ))}
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;
